<template>
  <v-dialog v-model="filterDialog" max-width="500">
    <v-card class="pa-8">
      <v-card-title class="text-h5 font-weight-black primary--text">
        Filtrar eventos
      </v-card-title>

      <v-card-text class="my-4">
        <v-row>
          <span> Por Campeonato </span>
        </v-row>
        <v-row class="mb-4">
          <v-select
            dense
            outlined
            hide-details="true"
            :items="competitions"
            item-text="name"
            item-value="id"
            color="black"
            class="mr-3 rounded-lg"
            clearable
            v-model="filters.competition_id"
          ></v-select>
        </v-row>
        <v-row>
          <span> Por Status </span>
        </v-row>
        <v-row class="mb-4">
          <v-select
            dense
            outlined
            hide-details="true"
            :items="filterStatuses"
            item-text="name"
            item-value="id"
            color="black"
            class="mr-3 rounded-lg"
            clearable
            multiple
            v-model="filters.statuses"
          ></v-select>
        </v-row>

        <v-row>
          <span> Por Data </span>
        </v-row>
        <v-row class="mb-4">
          <v-menu
            v-model="dateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                clearable
                v-model="formattedDate"
                placeholder="00/00/0000"
                hide-details
                append-icon="$datepick"
                readonly
                class="mr-3 rounded-lg"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="filters.date"
              no-title
              @input="dateMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-row>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn class="gradientbutton white--text" large @click="closeFilters">
          Filtrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "EventFilter",
  data: () => ({
    dateMenu: false,
  }),
  methods: {
    closeFilters() {
      this.$store.dispatch("event/setFilterDialog", false);
    },
  },
  computed: {
    ...mapGetters({
      filters: "event/currentFilters",
      competitions: "event/competitions",
      filterStatuses: "event/filterStatuses",
    }),
    formattedDate: {
      get() {
        if (this.filters.date == "") {
          return null;
        }
        return moment(this.filters.date).format("DD[/]MM[/]YYYY");
      },
      set(val) {
        if (val == null) {
          this.$store.dispatch("event/setFilters", { date: "" });
        }
      },
    },
    filterDialog: {
      get() {
        return this.$store.getters["event/filterDialog"];
      },
      set(val) {
        this.$store.dispatch("event/setFilterDialog", val);
      },
    },
  },
};
</script>
<style></style>
