<template>
  <v-layout>
    <side-bar></side-bar>
    <v-main>
      <v-container fluid style="max-width: 1400px">
        <h1 style="text-align: left">Olá, {{ currentUser.email }}</h1>
        <v-divider class="mb-3"></v-divider>
        <router-view />
      </v-container>
    </v-main>
  </v-layout>
</template>

<script>
import SideBar from "@/components/base/SideBar.vue";
import { mapGetters } from "vuex";
export default {
  components: { SideBar },
  data: () => ({
    //
  }),
  computed: {
    ...mapGetters({
      currentUser: "user/currentUser",
    }),
  },
};
</script>
