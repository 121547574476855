<template>
  <v-card width="436" height="96" class="box-shadow-rounded mb-4 mx-3">
    <v-card-title class="team-title-h6 justify-center">
      <v-col>
        <v-row class="justify-center">
          <v-icon> {{ this.modalityIcon(modality.name) }} </v-icon>
        </v-row>
        <v-row class="justify-center">
          <div>{{ modality.name }}</div>
        </v-row>
      </v-col>
    </v-card-title>
  </v-card>
</template>

<script>
export default {
  data: () => ({}),
  props: { modality: {} },
  methods: {
    modalityIcon(name) {
      if (name == "Basquete") {
        return "$basketball";
      } else if (name == "Futebo2l") {
        return "$football";
      } else {
        return "$football";
      }
    },
  },
};
</script>

<style scoped>
.box-shadow-rounded {
  box-shadow: 0px 0px 23px 1px rgb(0 0 0 / 25%) !important;
  border-radius: 18px !important;
}
.team-title-h6 {
  font-size: 1.25rem !important;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.0125em !important;
  font-family: "Barlow Bold" !important;
}
</style>
