//import axios from "@/api";
export default {
  namespaced: true,
  state: {
    events: [],
    event: {
      id: null,
      title: "Teste vs Teste",
      carousel: false,
      first_participant_info: {
        participant_name: "Teste",
        participant_short_name: "tst",
        participant_picture: null,
      },
      second_participant_info: {
        participant_name: "Teste",
        participant_short_name: "tst",
        participant_picture: null,
      },
      start_date: "2022-08-19T00:30:00.000Z",
      status: {
        status: "available",
        translated_status: "Previsto",
      },
      competition: {
        id: 1,
        name: "Brasileirão Série A",
      },
      markets: {
        market_winner: true,
        market_goals: true,
        market_both_score: true,
      },
    },
    competitions: [],
    modalities: [],
    statuses: [
      {
        id: "available",
        name: "Previsto",
      },
      {
        id: "ongoing",
        name: "Em andamento",
      },
      {
        id: "deferred",
        name: "Adiado",
      },
    ],
    filterStatuses: [
      {
        id: "available",
        name: "Previsto",
      },
      {
        id: "ongoing",
        name: "Em andamento",
      },
      {
        id: "deferred",
        name: "Adiado",
      },
      {
        id: "closed",
        name: "Encerrado",
      },
      {
        id: "canceled",
        name: "Cancelado",
      },
    ],
    filters: {
      competition_id: "",
      statuses: [],
      date: "",
    },
    filterDialog: false,
    eventDialog: {
      enabled: false,
      type: "create",
    },
    eventCancelDialog: false,
    search: "",
    pagination: {},
  },
  getters: {
    events: (state) => {
      return state.events;
    },
    currentEvent: (state) => {
      return state.event;
    },
    currentFilters: (state) => {
      return state.filters;
    },
    competitions: (state) => {
      return state.competitions;
    },
    modalities: (state) => {
      return state.modalities;
    },
    statuses: (state) => {
      return state.statuses;
    },
    filterStatuses: (state) => {
      return state.filterStatuses;
    },
    filterDialog: (state) => {
      return state.filterDialog;
    },
    eventDialog: (state) => {
      return state.eventDialog;
    },
    eventCancelDialog: (state) => {
      return state.eventCancelDialog;
    },
  },
  mutations: {
    SET_EVENTS(state, payload) {
      state.events = payload;
    },
    SET_EVENT(state, payload) {
      state.event = payload;
    },
    SET_COMPETITIONS(state, payload) {
      state.competitions = payload;
    },
    SET_MODALITIES(state, payload) {
      state.modalities = payload;
    },
    SET_FILTERS(state, payload) {
      state.filters = { ...state.filters, ...payload };
    },
    SET_FILTER_DIALOG(state, payload) {
      state.filterDialog = payload;
    },
    SET_EVENT_DIALOG(state, payload) {
      state.eventDialog = payload;
    },
    SET_EVENT_CANCEL_DIALOG(state, payload) {
      state.eventCancelDialog = payload;
    },
  },
  actions: {
    setEvents({ commit }, payload) {
      commit("SET_EVENTS", payload);
    },
    setEvent({ commit }, payload) {
      commit("SET_EVENT", payload);
    },
    setCompetitions({ commit }, payload) {
      commit("SET_COMPETITIONS", payload);
    },
    setModalities({ commit }, payload) {
      commit("SET_MODALITIES", payload);
    },
    setFilters({ commit }, payload) {
      commit("SET_FILTERS", payload);
    },
    setFilterDialog({ commit }, payload) {
      commit("SET_FILTER_DIALOG", payload);
    },
    setEventDialog({ commit }, payload) {
      commit("SET_EVENT_DIALOG", payload);
    },
    setEventCancelDialog({ commit }, payload) {
      commit("SET_EVENT_CANCEL_DIALOG", payload);
    },
  },
};
