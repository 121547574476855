import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import calendarIcon from "@/components/icons/CalendarIcon";
import cupIcon from "@/components/icons/CupIcon";
import cupBoldIcon from "@/components/icons/CupBoldIcon";
import ballIcon from "@/components/icons/BallIcon";
import filterIcon from "@/components/icons/FilterIcon";
import graphIcon from "@/components/icons/GraphIcon";
import moneyIcon from "@/components/icons/MoneyIcon";
import participantsIcon from "@/components/icons/ParticipantsIcon";
import settingsIcon from "@/components/icons/SettingsIcon";
import sitemapIcon from "@/components/icons/SitemapIcon";
import ticketIcon from "@/components/icons/TicketIcon";
import userIcon from "@/components/icons/UserIcon";
import exitIcon from "@/components/icons/ExitIcon";
import eyeIcon from "@/components/icons/EyeIcon";
import eyeoffIcon from "@/components/icons/EyeoffIcon";
import emailIcon from "@/components/icons/EmailIcon";
import padlockIcon from "@/components/icons/PadlockIcon";
import datePickIcon from "@/components/icons/DatePickIcon";
import switchOnIcon from "@/components/icons/SwitchOnIcon";
import switchOffIcon from "@/components/icons/SwitchOffIcon";
import hintIcon from "@/components/icons/HintIcon";
import destroyIcon from "@/components/icons/DestroyIcon";
import basketballIcon from "@/components/icons/BasketballIcon";
import footballIcon from "@/components/icons/FootballIcon";
Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#1C2360",
        secondary: "#FFFFFF",
        lightBlue: "#5ACCDB",
        liveBlue: "#002AA0",
        backgroundBlue: "#F0F3FF",
        outlineGrey: "#CBD5E0",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        available: "#50DA8F",
        ongoing: "#FF4B00",
        deferred: "#FCEA10",
      },
    },
  },
  icons: {
    values: {
      calendar: { component: calendarIcon },
      cup: { component: cupIcon },
      cup_bold: { component: cupBoldIcon },
      ball: { component: ballIcon },
      filter: { component: filterIcon },
      graph: { component: graphIcon },
      money: { component: moneyIcon },
      participants: { component: participantsIcon },
      settings: { component: settingsIcon },
      sitemap: { component: sitemapIcon },
      ticket: { component: ticketIcon },
      user: { component: userIcon },
      exit: { component: exitIcon },
      eye: { component: eyeIcon },
      eye_off: { component: eyeoffIcon },
      email: { component: emailIcon },
      padlock: { component: padlockIcon },
      datepick: { component: datePickIcon },
      switch_on: { component: switchOnIcon },
      switch_off: { component: switchOffIcon },
      hint: { component: hintIcon },
      destroy: { component: destroyIcon },
      football: { component: footballIcon },
      basketball: { component: basketballIcon },
    },
  },
});
