<template>
  <v-app>
    <router-view></router-view>
    <v-snackbar
      top
      :color="currentAlert.type"
      v-model="currentAlert.enabled"
      :timeout="2000"
    >
      {{ currentAlert.text }}

      <template v-slot:action="{ attrs }">
        <v-btn text small v-bind="attrs" @click="currentAlert.enabled = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import axios from "@/api";
import { mapGetters } from "vuex";
export default {
  name: "App",
  created() {
    const access_token = localStorage.access_token;
    axios.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;
    this.getCurrentUser();
  },
  data: () => ({
    //
  }),
  methods: {
    getCurrentUser() {
      axios
        .get("admin/auth/me")
        .then(({ data }) => {
          this.$store.dispatch("user/setUser", data.admin);
        })
        .catch(() => {});
    },
  },
  computed: {
    ...mapGetters({
      currentAlert: "base/currentAlert",
    }),
  },
};
</script>

<style>
#app {
  font-family: "Barlow", Times, Arial, sans-serif;
}
@font-face {
  font-family: "Barlow";
  src: local("Barlow"), url(../src/fonts/Barlow-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Barlow Bold";
  src: local("Barlow Bold"),
    url(../src/fonts/Barlow-Bold.ttf) format("truetype");
}

.v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 40px;
}
.v-text-field--enclosed .v-input__prepend-inner {
  margin-top: 10px;
}
.v-text-field--outlined .v-label {
  top: 10px;
}

::-webkit-scrollbar {
  background: #ffffff;
  width: 12px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
::-webkit-scrollbar-thumb {
  background: #929393;
  border: 3px solid white;
  border-radius: 20px;
}
</style>
