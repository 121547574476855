export default {
  namespaced: true,
  state: {
    alert: {
      text: "Alert!",
      type: "success",
      enabled: false,
    },
  },
  getters: {
    currentAlert: (state) => {
      return state.alert;
    },
  },
  mutations: {
    SET_ALERT(state, payload) {
      state.alert = payload;
    },
    DISABLE_CURRENT_ALERT(state) {
      state.alert = { ...state.alert, enabled: false };
    },
  },
  actions: {
    setAlert({ commit }, payload) {
      commit("DISABLE_CURRENT_ALERT");
      commit("SET_ALERT", structuredClone(payload));
    },
  },
};
