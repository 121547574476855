<template>
  <v-flex>
    <v-row class="align-center">
      <v-col cols="5">
        <h1>Gerenciar Campeonatos</h1>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="4">
        <v-row class="align-center ma-0">
          <v-text-field
            outlined
            hide-details="true"
            prepend-inner-icon="mdi-magnify"
            color="black"
            label="Buscar"
            class="mr-3 rounded-lg"
          ></v-text-field>
          <v-btn outlined color="outlineGrey" class="mr-3 rounded-lg">
            <v-icon color="black">$filter</v-icon>
          </v-btn>
          <v-btn outlined color="outlineGrey" class="mr-3 rounded-lg">
            <v-icon color="black">mdi-plus</v-icon>
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <competition-card
        v-for="competition in competitions"
        :key="competition.id"
        :competition="competition"
      ></competition-card>
    </v-row>
  </v-flex>
</template>

<script>
import axios from "@/api";
import { mapGetters } from "vuex";
import CompetitionCard from "@/components/competitions/CompetitionCard.vue";
export default {
  name: "CompetitionView",
  components: { CompetitionCard },
  data: () => ({}),
  created() {
    this.getCompetitions();
  },
  methods: {
    getCompetitions() {
      axios.get("admin/competitions").then(({ data }) => {
        this.$store.dispatch("event/setCompetitions", data.competitions);
      });
    },
  },
  computed: {
    ...mapGetters({
      competitions: "event/competitions",
    }),
  },
};
</script>

<style scoped>
.v-btn:not(.v-btn--round).v-size--default {
  width: 40px;
  height: 40px;
  min-width: 40px;
}
.v-text-field--outlined >>> fieldset {
  border-color: #cbd5e0;
}
</style>
