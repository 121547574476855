export default {
  namespaced: true,
  state: {
    events: [],
    users: [],
    analyticDialog: {
      enabled: false,
      type: "",
    },
  },
  getters: {
    events: (state) => {
      return state.events;
    },
    users: (state) => {
      return state.users;
    },
    analyticDialog: (state) => {
      return state.analyticDialog;
    },
  },
  mutations: {
    SET_EVENTS(state, payload) {
      state.events = payload;
    },
    SET_USERS(state, payload) {
      state.users = payload;
    },
    SET_ANALYTIC_DIALOG(state, payload) {
      state.analyticDialog = payload;
    },
  },
  actions: {
    setEvents({ commit }, payload) {
      commit("SET_EVENTS", payload);
    },
    setUsers({ commit }, payload) {
      commit("SET_USERS", payload);
    },
    setAnalyticDialog({ commit }, payload) {
      commit("SET_ANALYTIC_DIALOG", payload);
    },
  },
};
