<template>
  <v-dialog v-model="analyticDialog.enabled" max-width="500px" height="800px">
    <v-card class="pa-8">
      <v-card-title class="text-h5 font-weight-black primary--text">
        Exportar Dados
      </v-card-title>

      <v-card-text class="my-4">
        <div v-if="analyticDialog.type == 'event'">
          <v-row>
            <span> Por Evento </span>
          </v-row>
          <v-row class="mb-4">
            <v-autocomplete
              dense
              outlined
              hide-details="true"
              :items="events"
              item-text="title"
              item-value="id"
              color="black"
              class="mr-3 rounded-lg"
              clearable
              v-model="event_id"
            ></v-autocomplete>
          </v-row>
        </div>
        <div v-if="analyticDialog.type == 'date'">
          <v-row>
            <span> Por Data </span>
          </v-row>
          <v-row class="mb-4">
            <v-menu
              v-model="menu1"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date_from"
                  label="Do início do dia"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date_from"
                no-title
                @input="menu1 = false"
              ></v-date-picker>
            </v-menu>
          </v-row>
          <v-row class="mb-4">
            <v-menu
              v-model="menu2"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date_to"
                  label="Até o fim do dia"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date_to"
                no-title
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-row>
        </div>
        <div v-if="analyticDialog.type == 'user'">
          <v-row>
            <span> Por Usuário </span>
          </v-row>
          <v-row class="mb-4">
            <v-autocomplete
              dense
              outlined
              hide-details="true"
              :items="users"
              item-text="username"
              item-value="id"
              color="black"
              class="mr-3 rounded-lg"
              clearable
              v-model="user_id"
            ></v-autocomplete>
          </v-row>
        </div>
        <div v-if="analyticDialog.type == 'users_report'">
          <h3>*Dados de todos os usuários e o respectivo saldo atual</h3>
        </div>
      </v-card-text>
      <div style="height: 300px"></div>

      <v-card-actions class="justify-center">
        <v-btn class="gradientbutton white--text" large @click="getData">
          Exportar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "@/api";
import { mapGetters } from "vuex";
export default {
  data: () => ({
    user_id: null,
    event_id: null,
    date_from: null,
    date_to: null,
    menu1: false,
    menu2: false,
  }),
  computed: {
    ...mapGetters({
      analyticDialog: "data/analyticDialog",
      events: "data/events",
      users: "data/users",
    }),
  },
  watch: {
    date_from(val) {
      if (val > this.date_to) {
        this.date_to = val;
      }
    },
    date_to(val) {
      if (val < this.date_from) {
        this.date_from = val;
      }
    },
  },
  methods: {
    getData() {
      var url = "";
      var params = {};
      if (this.analyticDialog.type == "user") {
        url = `/admin/spreadsheet/user/${this.user_id}`;
      } else if (this.analyticDialog.type == "event") {
        url = `/admin/spreadsheet/event/${this.event_id}`;
      } else if (this.analyticDialog.type == "date") {
        url = "/admin/spreadsheet/date";
        params = {
          start_date: this.date_from,
          end_date: this.date_to,
        };
        console.log(params);
      } else if (this.analyticDialog.type == "users_report") {
        url = "/admin/spreadsheet/users_report";
      } else {
        console.log(url);
        return;
      }
      this.$store.dispatch("data/setAnalyticDialog", {
        ...this.analyticDialog,
        enabled: false,
      });
      axios
        .get(url, {
          params: params,
          responseType: "blob",
        })
        .then((response) => {
          const headerval = response.headers["content-disposition"];
          var filename = headerval
            .split(";")[1]
            .split("=")[1]
            .replace('"', "")
            .replace('"', "");
          const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = fileUrl;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
        });
    },
  },
};
</script>
