<template>
  <v-dialog v-model="eventCancelDialog" max-width="200px">
    <v-card
      ><v-card-title> Confirmação </v-card-title>
      <v-card-subtitle> Deseja excluir o evento? </v-card-subtitle>
      <v-card-actions>
        <v-btn class="gradientbutton white--text" @click="cancelEvent()"
          >Sim</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
          class="borderbutton black--text"
          @click="eventCancelDialog = false"
          >Não</v-btn
        >
      </v-card-actions></v-card
    >
  </v-dialog>
</template>

<script>
import axios from "@/api";
import { mapActions } from "vuex";
import { mapGetters } from "vuex";
export default {
  data: () => ({}),
  computed: {
    ...mapGetters({
      eventCancelDialog: "event/eventCancelDialog",
      currentEvent: "event/currentEvent",
    }),
    eventCancelDialog: {
      get() {
        return this.$store.getters["event/eventCancelDialog"];
      },
      set(val) {
        this.$store.dispatch("event/setEventCancelDialog", val);
      },
    },
  },
  methods: {
    ...mapActions({
      setAlert: "base/setAlert",
    }),
    cancelEvent() {
      axios
        .post(`/admin/events/${this.currentEvent.id}/cancel`)
        .then(() => {
          this.$emit("reload_dialog", this.currentEvent.id);
          this.setAlert({
            text: "Evento cancelado!",
            type: "success",
            enabled: true,
          });
        })
        .catch((err) => {
          this.setAlert({
            text: err.response.data.message || "Erro!",
            type: "error",
            enabled: true,
          });
        })
        .finally(() => {
          this.eventCancelDialog = false;
          this.$store.dispatch("event/setEventDialog", {
            enabled: false,
            type: "view",
          });
        });
    },
  },
};
</script>
