<template>
  <v-dialog v-model="eventDialog.enabled" max-width="700px">
    <v-card class="pa-7 rounded-r-0">
      <v-card-title v-if="eventDialog.type == 'view'" class="pa-3">
        <span class="text-h5 font-weight-black primary--text">
          Visualizar evento
        </span>
      </v-card-title>
      <v-card-text v-if="eventDialog.type == 'view'" class="pa-0">
        <v-col class="justify-center">
          <v-card
            elevation="3"
            style="justify-content: space-around; border-radius: 12px"
            max-width="100%"
            min-height="162"
            class="mx-4"
          >
            <v-col>
              <v-row>
                <v-col class="ma-3 mb-0">
                  <v-row>
                    <v-icon class="mr-1" :color="currentEvent.status.status">
                      mdi-circle-medium
                    </v-icon>
                    <div class="body-1 justify-center">
                      {{ currentEvent.status.translated_status }}
                    </div>
                  </v-row>
                  <v-row class="justify-center px-0">
                    <v-col>
                      <v-row class="justify-end align-center">
                        <div class="team-title text-uppercase mx-1">
                          {{
                            getParticipantName(
                              currentEvent.first_participant_info
                            )
                          }}
                        </div>
                        <v-card elevation="1" class="rounded-circle mx-1">
                          <v-img
                            contain
                            class="ma-2"
                            :src="
                              currentEvent.first_participant_info
                                .participant_picture
                            "
                            height="49"
                            width="49"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="primary"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-card>
                        <v-text-field
                          v-if="settingResult"
                          v-model="first_score"
                          maxlength="3"
                          hide-details="auto"
                          class="centered-text pa-0 mx-1"
                        ></v-text-field>
                        <div
                          v-else
                          class="self-align-end team-score text-uppercase mx-1 grey--text"
                        >
                          {{ currentEvent.score.first_participant }}
                        </div>
                      </v-row>
                    </v-col>
                    <v-col cols="1" class="align-self-center">
                      <v-row class="justify-center">
                        <div class="mx-1">vs</div>
                      </v-row>
                    </v-col>
                    <v-col>
                      <v-row class="justify-start align-center">
                        <v-text-field
                          v-if="settingResult"
                          v-model="second_score"
                          maxlength="3"
                          hide-details="auto"
                          class="centered-text pa-0 mx-1"
                        ></v-text-field>
                        <div
                          v-else
                          class="self-align-end team-score text-uppercase mx-1 grey--text"
                        >
                          {{ currentEvent.score.second_participant }}
                        </div>
                        <v-card elevation="1" class="rounded-circle mx-1">
                          <v-img
                            contain
                            class="ma-2"
                            :src="
                              currentEvent.second_participant_info
                                .participant_picture
                            "
                            height="49"
                            width="49"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="primary"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-card>
                        <div class="team-title text-uppercase">
                          {{
                            getParticipantName(
                              currentEvent.second_participant_info
                            )
                          }}
                        </div>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-row class="align-center my-0 pa-0">
                    <v-divider class="border-color: liveBlue"></v-divider>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="liveBlue--text pb-2">
                <v-col>
                  <v-row class="justify-end pr-2">
                    <v-icon color="red">$calendar</v-icon>
                    <div class="body-1 mx-2">
                      {{ formatDate(currentEvent.start_date) }}
                    </div>
                  </v-row>
                </v-col>
                <v-col>
                  <v-row class="justify-start pl-2">
                    <v-icon color="liveBlue">$cup</v-icon>
                    <div class="mx-2">{{ currentEvent.competition.name }}</div>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-card>
        </v-col>
      </v-card-text>
      <v-card-actions
        v-if="eventDialog.type == 'view'"
        style="justify-content: space-around"
      >
        <v-btn
          v-if="!getFormDisabled() && settingResult == false"
          class="gradientbutton mylargebutton white--text"
          x-large
          text
          @click="settingResult = true"
        >
          Atribuir resultado
        </v-btn>
        <div v-else-if="!getFormDisabled()">
          <v-btn
            class="gradientbutton white--text mr-2"
            x-large
            text
            @click="submitResult()"
          >
            Confirmar
          </v-btn>
          <v-btn
            class="borderbutton black--text ml-2"
            x-large
            text
            @click="cancelResult()"
          >
            Cancelar
          </v-btn>
        </div>
      </v-card-actions>
      <v-divider
        v-if="
          eventDialog.type == 'view' && currentEvent.status.status != 'closed'
        "
        class="ma-3"
      ></v-divider>
      <v-form :disabled="getFormDisabled()" ref="form" v-model="valid">
        <v-card-title class="pa-3">
          <span class="text-h5 font-weight-black primary--text">{{
            eventDialog.type == "view"
              ? "Editar este evento"
              : "Criar um novo evento"
          }}</span>
          <v-spacer></v-spacer>
          <v-checkbox
            style="transform: scale(1.8)"
            height="36"
            width="36"
            v-model="event.carousel"
            :ripple="false"
            inset
            on-icon="$switch_on"
            off-icon="$switch_off"
            hide-details="true"
            class="ma-0 pa-0"
          ></v-checkbox
          ><span class="carousel mx-1">Destaque</span>
          <v-btn
            v-if="eventDialog.type == 'view'"
            depressed
            :ripple="false"
            class="destroyButton pa-0 ml-3"
            @click="cancelEvent()"
            ><v-icon>$destroy</v-icon><span>Excluir</span></v-btn
          >
        </v-card-title>
        <v-card-text class="black--text pa-0">
          <v-container>
            <v-row class="pa-0">
              <v-col cols="12" class="pt-0">
                <div class="text-subtitle-1">Modalidade</div>
                <v-autocomplete
                  :items="modalities"
                  item-text="name"
                  item-value="id"
                  v-model="modalitieId"
                  placeholder="Ex: Time/Equipe"
                  outlined
                  hide-details
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" class="pt-0">
                <div class="text-subtitle-1">Campeonato</div>
                <v-autocomplete
                  :items="competitions"
                  item-text="name"
                  item-value="id"
                  v-model="event.competitionId"
                  :error-messages="custom_erros.competition"
                  :rules="requiredRule"
                  placeholder="Ex: Libertadores da América"
                  outlined
                  hide-details="auto"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" class="pt-0">
                <div class="text-subtitle-1">Participante 1</div>
                <v-text-field
                  v-model="event.participant1_name"
                  :error-messages="custom_erros.first_participant"
                  hide-details="auto"
                  outlined
                  placeholder="Ex: Grêmio"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="pt-0">
                <div class="text-subtitle-1">Participante 2</div>
                <v-text-field
                  v-model="event.participant2_name"
                  :error-messages="custom_erros.second_participant"
                  hide-details="auto"
                  outlined
                  placeholder="Ex: Internacional"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="pt-0">
                <div class="text-subtitle-1">Sigla Participante 1</div>
                <v-text-field
                  v-model="event.participant1_short_name"
                  :error-messages="custom_erros.first_participant_short_name"
                  hide-details="auto"
                  outlined
                  placeholder="Ex: GRE"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="pt-0">
                <div class="text-subtitle-1">Sigla Participante 2</div>
                <v-text-field
                  v-model="event.participant2_short_name"
                  :error-messages="custom_erros.second_participant_short_name"
                  hide-details="auto"
                  outlined
                  placeholder="Ex: INT"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="pt-0">
                <div class="text-subtitle-1">
                  Brasão/logotipo Participante 1
                </div>
                <v-row class="justify-center">
                  <v-col cols="4">
                    <v-img
                      class="elevation-4 rounded-circle"
                      :src="picture1_preview"
                      max-width="80"
                      height="80"
                      contain
                      v-on:click="
                        getFormDisabled() ? null : $refs.inputPicture1.click()
                      "
                    >
                    </v-img>
                    <input
                      type="file"
                      ref="inputPicture1"
                      style="display: none"
                      @change="uploadPicture1($event)"
                    />
                  </v-col>
                  <v-col cols="2" class="align-self-end"
                    ><v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="primary" dark v-bind="attrs" v-on="on">
                          $hint
                        </v-icon>
                      </template>
                      <span>Clique na imagem para fazer upload</span>
                    </v-tooltip></v-col
                  >
                </v-row>
                <span class="text-caption error--text mx-2">
                  {{
                    custom_erros.second_participant_picture
                      ? custom_erros.second_participant_picture[0]
                      : ""
                  }}
                </span>
              </v-col>
              <v-col cols="12" sm="6" class="pt-0">
                <div class="text-subtitle-1">
                  Brasão/logotipo Participante 2
                </div>
                <v-row class="justify-center">
                  <v-col cols="4">
                    <v-img
                      class="elevation-4 rounded-circle"
                      :src="picture2_preview"
                      max-width="80"
                      height="80"
                      contain
                      v-on:click="
                        getFormDisabled() ? null : $refs.inputPicture2.click()
                      "
                    >
                    </v-img>
                    <input
                      type="file"
                      ref="inputPicture2"
                      style="display: none"
                      @change="uploadPicture2($event)"
                    />
                  </v-col>
                  <v-col cols="2" class="align-self-end"
                    ><v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="primary" dark v-bind="attrs" v-on="on">
                          $hint
                        </v-icon>
                      </template>
                      <span>Clique na imagem para fazer upload</span>
                    </v-tooltip></v-col
                  >
                </v-row>
                <span class="text-caption error--text mx-2">
                  {{
                    custom_erros.second_participant_picture
                      ? custom_erros.second_participant_picture[0]
                      : ""
                  }}
                </span>
              </v-col>
              <v-col cols="12" sm="6" class="pt-0">
                <div class="text-subtitle-1">Data do evento</div>
                <v-menu
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      v-model="dateFormatted"
                      :error-messages="custom_erros.start_date"
                      :rules="requiredRule"
                      placeholder="00/00/0000"
                      hide-details="auto"
                      append-icon="$datepick"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    no-title
                    @input="dateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" class="pt-0">
                <div class="text-subtitle-1">Horário do evento</div>
                <input
                  :disabled="getFormDisabled()"
                  type="text"
                  placeholder="00:00"
                  v-model="time"
                  class="px-3 timefield"
                  maxlength="5"
                />
              </v-col>
              <v-col cols="12" class="pt-0">
                <div class="text-subtitle-1">Mercados</div>
                <v-checkbox
                  hide-details="auto"
                  :ripple="false"
                  v-model="event.market_winner"
                  :error-messages="custom_erros.market"
                  label="Vencedor da partida"
                ></v-checkbox>
                <v-checkbox
                  hide-details="auto"
                  :ripple="false"
                  v-model="event.market_goals"
                  :error-messages="custom_erros.market"
                  label="Número de gols"
                ></v-checkbox>
                <v-checkbox
                  hide-details="auto"
                  :ripple="false"
                  v-model="event.market_both_score"
                  :error-messages="custom_erros.market"
                  label="Ambos marcam"
                ></v-checkbox>
              </v-col>
              <v-col v-if="eventDialog.type == 'view'" cols="12" class="pt-0">
                <div class="text-subtitle-1">Status</div>
                <v-autocomplete
                  :items="statuses"
                  item-text="name"
                  item-value="id"
                  v-model="event.statusId"
                  placeholder="Ex: Previsto"
                  outlined
                  hide-details
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions style="justify-content: space-around">
          <v-btn
            v-if="!getFormDisabled()"
            class="gradientbutton white--text"
            large
            text
            :loading="saveLoading"
            @click="submitForm()"
            :disabled="false"
          >
            Salvar
          </v-btn>

          <v-btn
            class="borderbutton black--text"
            large
            text
            @click="closeForm()"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <cancel-dialog />
  </v-dialog>
</template>

<script>
import CancelDialog from "@/components/events/EventCancelDialog.vue";

import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import axios from "@/api";
import moment from "moment";
export default {
  components: { CancelDialog },
  data: () => ({
    valid: true,
    saveLoading: false,
    date: null,
    dateMenu: false,
    time: null,
    modalitieId: null,
    event: {
      id: null,
      carousel: false,
      competitionId: null,
      participant1_name: "",
      participant2_name: "",
      participant1_short_name: "",
      participant2_short_name: "",
      statusId: null,
      market_winner: false,
      market_goals: false,
      market_both_score: false,
    },
    picture1: null,
    picture2: null,
    picture1_preview: require("@/assets/upload-placeholder.png"),
    picture2_preview: require("@/assets/upload-placeholder.png"),
    custom_erros: {},
    requiredRule: [(v) => !!v || "Campo obrigatório."],
    settingResult: false,
    first_score: 0,
    second_score: 0,
  }),
  created() {
    // console.log("Abri o dialog");
  },
  methods: {
    getFormDisabled() {
      let status = this.currentEvent.status.status;
      return status == "closed" || status == "canceled";
    },
    getParticipantName(info) {
      if (info.participant_name.length > 8) {
        return info.participant_short_name;
      } else {
        return info.participant_name;
      }
    },
    formatDate(crudeDate) {
      return moment(crudeDate).format("DD/MM/YYYY");
    },
    uploadPicture1(event) {
      this.picture1 = event.target.files[0];
      this.picture1_preview = URL.createObjectURL(event.target.files[0]);
      event.target.value = "";
    },
    uploadPicture2(event) {
      this.picture2 = event.target.files[0];
      this.picture2_preview = URL.createObjectURL(event.target.files[0]);
      event.target.value = "";
    },
    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.modalitieId = null;
      this.event = {
        carousel: false,
        competitionId: null,
        participant1_name: "",
        participant2_name: "",
        participant1_short_name: "",
        participant2_short_name: "",
        statusId: null,
        market_winner: false,
        market_goals: false,
        market_both_score: false,
      };
      this.date = null;
      this.time = null;
    },
    closeForm() {
      this.$store.dispatch("event/setEventDialog", {
        enabled: false,
        type: "create",
      });
    },
    getCompetitions(modality_id) {
      axios.get(`competitions?modality_id=${modality_id}`).then(({ data }) => {
        this.$store.dispatch("event/setCompetitions", data.competitions);
      });
    },
    cancelResult() {
      this.first_score = 0;
      this.second_score = 0;
      this.settingResult = false;
    },
    submitResult() {
      var params = {
        event: {
          first_participant_score: this.first_score,
          second_participant_score: this.second_score,
        },
      };
      axios
        .put(`/admin/events/${this.currentEvent.id}/close`, params)
        .then(() => {
          this.$emit("reload_dialog", this.currentEvent.id);
          this.$emit("reload");
          this.settingResult = false;
        });
    },
    submitForm() {
      this.saveLoading = true;
      this.custom_erros = {};
      const formData = new FormData();
      if (this.event.participant1_name != null) {
        formData.append(
          "event[first_participant]",
          this.event.participant1_name
        );
      }
      if (this.event.participant2_name != null) {
        formData.append(
          "event[second_participant]",
          this.event.participant2_name
        );
      }
      if (this.event.participant1_short_name != null) {
        formData.append(
          "event[first_participant_short_name]",
          this.event.participant1_short_name
        );
      }
      if (this.event.participant2_short_name != null) {
        formData.append(
          "event[second_participant_short_name]",
          this.event.participant2_short_name
        );
      }

      if (this.picture1 != null) {
        formData.append("event[first_participant_picture]", this.picture1);
      }

      if (this.picture2 != null) {
        formData.append("event[second_participant_picture]", this.picture2);
      }

      if (this.event.market_winner != null) {
        formData.append("event[market_winner]", this.event.market_winner);
      }
      if (this.event.market_goals != null) {
        formData.append("event[market_goals]", this.event.market_goals);
      }
      if (this.event.market_both_score != null) {
        formData.append(
          "event[market_both_score]",
          this.event.market_both_score
        );
      }

      formData.append("event[start_date]", this.dateTime);
      formData.append("event[carousel]", this.event.carousel);
      formData.append("event[competition_id]", this.event.competitionId);
      formData.append("event[status]", this.event.statusId);
      if (this.eventDialog.type == "create") {
        axios
          .post("/admin/events", formData)
          .then(() => {
            this.$emit("reload");
            this.closeForm();
          })
          .catch((err) => {
            this.custom_erros = err.response.data;
          })
          .finally(() => {
            this.saveLoading = false;
          });
      } else {
        axios
          .put(`/admin/events/${this.currentEvent.id}`, formData)
          .then(() => {
            this.$emit("reload");
            this.closeForm();
          })
          .catch((err) => {
            this.custom_erros = err.response.data;
          })
          .finally(() => {
            this.saveLoading = false;
          });
      }
    },
    cancelEvent() {
      this.$store.dispatch("event/setEventCancelDialog", true);
    },
    ...mapActions({
      setAlert: "base/setAlert",
    }),
  },
  computed: {
    ...mapGetters({
      statuses: "event/statuses",
      competitions: "event/competitions",
      modalities: "event/modalities",
      currentEvent: "event/currentEvent",
      eventDialog: "event/eventDialog",
    }),
    eventDialog: {
      get() {
        return this.$store.getters["event/eventDialog"];
      },
      set(val) {
        this.$store.dispatch("event/setEventDialog", val);
      },
    },
    dateFormatted: {
      get() {
        if (this.date == null) {
          return null;
        }
        return moment(this.date, "YYYY-MM-DD hh:mm:ss").format(
          "DD[/]MM[/]YYYY"
        );
      },
      set() {
        this.date = null;
      },
    },
    dateTime() {
      return `${this.date} ${this.time} -03:00`;
    },
  },
  watch: {
    eventDialog(val) {
      if (val.enabled) {
        if (this.$refs.form != undefined) {
          this.custom_erros = {};
        }
        this.picture1_preview = require("@/assets/upload-placeholder.png");
        this.picture2_preview = require("@/assets/upload-placeholder.png");
        this.picture1 = null;
        this.picture2 = null;
        if (val.type == "view") {
          //tratar o evento aqui
          this.modalitieId = this.currentEvent.competition.modality.id;
          this.event = {
            carousel: this.currentEvent.carousel,
            competitionId: this.currentEvent.competition.id,
            participant1_name:
              this.currentEvent.first_participant_info.participant_name,
            participant2_name:
              this.currentEvent.second_participant_info.participant_name,
            participant1_short_name:
              this.currentEvent.first_participant_info.participant_short_name,
            participant2_short_name:
              this.currentEvent.second_participant_info.participant_short_name,
            statusId: this.currentEvent.status.status,
            market_winner: this.currentEvent.markets.market_winner,
            market_goals: this.currentEvent.markets.market_goals,
            market_both_score: this.currentEvent.markets.market_both_score,
          };
          this.date = moment(this.currentEvent.start_date).format("YYYY-MM-DD");
          this.time = moment(this.currentEvent.start_date).format("HH:mm");
          if (
            this.currentEvent.first_participant_info.participant_picture != null
          ) {
            this.picture1_preview =
              this.currentEvent.first_participant_info.participant_picture;
          }
          if (
            this.currentEvent.second_participant_info.participant_picture !=
            null
          ) {
            this.picture2_preview =
              this.currentEvent.second_participant_info.participant_picture;
          }
        } else if (val.type == "create") {
          this.resetForm();
          this.custom_erros = {};
        }
      }
    },
    modalitieId(val) {
      this.getCompetitions(val);
    },
    time() {
      if (this.time != null) {
        this.time = this.time
          .replace(/[^0-9]/g, "")
          .replace(/^(\d{2})(\d{2})?/g, "$1:$2");
      }
    },
  },
};
</script>
<style>
.gradientbutton {
  background: linear-gradient(286.17deg, #002aa0 0%, #1c2360 100%);
  box-shadow: 3.5px 7px 20px rgba(104, 66, 255, 0.25);
  border-radius: 8px;
  font-weight: 800;
}
.gradientbutton:disabled {
  background: linear-gradient(286.17deg, #a0a0a0 0%, #5f5f5f 100%);
}
.mylargebutton {
  height: 60px;
  width: 300px;
}
.largebutton2 {
  width: 213px;
}
.borderbutton {
  border: 1px solid #1877f2;
  border-radius: 8px;
  font-weight: 800;
  background-color: #ffffff !important;
}
.timefield {
  height: 40px;
  width: 100%;
  border: solid #919191 1px;
  border-radius: 4px;
}
.destroyButton {
  color: rgb(0, 0, 0) !important;
  background-color: #ffffff !important;
  caret-color: #000000 !important;
  font-size: 18px !important;
  line-height: 16px !important;
  font-weight: 600 !important;
  text-decoration-line: underline !important;
  text-transform: capitalize !important;
}
.destroyButton::before {
  color: transparent;
}
.v-text-field--enclosed .v-input__append-inner {
  margin-top: 8px;
}
.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 4px;
}
</style>
<style scoped>
::-webkit-scrollbar {
  width: 4px;
}
.team-title {
  font-size: 1.5rem !important;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.0125em !important;
  font-family: "Barlow Bold" !important;
}
.team-score {
  font-size: 1.75rem !important;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.0125em !important;
  font-family: "Barlow Bold" !important;
}
.carousel {
  font-size: 1.25rem !important;
  letter-spacing: 0.0125em !important;
  font-family: "Barlow Bold" !important;
  font-weight: 700;
  line-height: 16px;
}
.centered-text {
  max-width: 30px;
}
.centered-text >>> input {
  text-align: center !important;
}
</style>
