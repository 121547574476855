import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "@/views/HomeView";
import EventView from "@/views/event/EventView";
import CompetitionView from "@/views/competition/CompetitionView";
import ModalityView from "@/views/modality/ModalityView";
import HomeLayout from "@/views/HomeLayout";
import AuthLayout from "@/views/authentication/AuthLayout";
import LoginView from "@/views/authentication/LoginView";
import AnalyticView from "@/views/analytics/AnalyticView";

Vue.use(VueRouter);

const hasToken = () => localStorage.getItem("access_token") != null;

const checkUserAuthentication = (to, from, next) => {
  if (hasToken()) {
    next();
    return;
  }
  next({ path: "/autenticar/entrar" });
};

const routes = [
  {
    path: "",
    redirect: { name: "admin" },
  },
  {
    path: "/autenticar",
    component: AuthLayout,
    children: [
      {
        path: "entrar",
        name: "login",
        component: LoginView,
      },
    ],
  },
  {
    path: "/admin",
    component: HomeLayout,
    beforeEnter: checkUserAuthentication,
    children: [
      {
        path: "",
        name: "admin",
        component: HomeView,
      },
      {
        path: "eventos",
        name: "events",
        component: EventView,
      },
      {
        path: "competicoes",
        name: "competitions",
        component: CompetitionView,
      },
      {
        path: "modalidades",
        name: "modalities",
        component: ModalityView,
      },
      {
        path: "dados",
        name: "analytics",
        component: AnalyticView,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
