<template>
  <v-navigation-drawer app min-width="256" permanent color="primary">
    <v-list>
      <v-list-item class="justify-center my-10">
        <v-img src="@/assets/valeu-logo.svg" max-width="160" />
      </v-list-item>
    </v-list>

    <v-list nav class="mx-4">
      <v-list-item
        v-for="(item, i) in items"
        :key="i"
        active-class="lightBlue"
        link
        :to="{ name: item.routerName }"
        :disabled="item.disabled"
        :class="getItemClass(item.disabled)"
      >
        <v-list-item-icon
          ><v-icon color="primary">{{ item.icon }}</v-icon></v-list-item-icon
        >
        <v-list-item-title style="font-weight: 700" color="primary">{{
          item.label
        }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "SideBar",
  data: () => ({
    items: [
      {
        routerName: "events",
        label: "Eventos",
        icon: "$ball",
        disabled: false,
      },
      {
        routerName: "competitions",
        label: "Campeonatos",
        icon: "$cup_bold",
        disabled: false,
      },
      {
        routerName: "modalities",
        label: "Modalidades",
        icon: "$sitemap",
        disabled: false,
      },
      {
        routerName: "analytics",
        label: "Dados",
        icon: "$graph",
        disabled: false,
      },
      {
        routerName: "login",
        label: "Sair",
        icon: "$exit",
        disabled: false,
      },
    ],
  }),
  methods: {
    getItemClass(disabled) {
      if (disabled) {
        return "grey darkgrey--text rounded-lg";
      }
      return "secondary primary--text rounded-lg";
    },
  },
};
</script>

<style>
/* .theme--light.v-list-item {
  color: #1c2360 !important;
  background-color: #fff;
  border-color: #fff;
}
.theme--light.v-list-item--disabled {
  color: darkgrey !important;
  background-color: grey !important;
  border-color: grey !important;
} */
</style>
