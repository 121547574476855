<template>
  <v-flex>
    <v-row class="align-center">
      <v-col cols="5">
        <h1>Dados</h1>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row>
      <!-- <v-col>
        <v-row>
          <v-card
            v-for="(item, index) in items"
            :key="index"
            width="209"
            height="155"
            class="box-shadow-rounded my-3 mx-3"
          >
            <v-card-title class="analityc-text justify-center pt-6">
              {{ item.title }}
            </v-card-title>
            <v-card-subtitle class="text-center">
              {{ item.text }}</v-card-subtitle
            >
          </v-card>
        </v-row>
      </v-col> -->
      <v-col cols="4">
        <v-btn
          height="68"
          block
          class="gradientbutton white--text mb-5"
          @click="openDialog('users_report')"
          >Planilha de Usuários</v-btn
        >
        <v-btn
          height="68"
          block
          class="gradientbutton white--text mb-5"
          @click="openDialog('event')"
          >Por evento</v-btn
        >
        <v-btn
          height="68"
          block
          class="gradientbutton white--text mb-5"
          @click="openDialog('date')"
          >Por data</v-btn
        >
        <v-btn
          height="68"
          block
          class="gradientbutton white--text mb-5"
          @click="openDialog('user')"
          >Por usuário</v-btn
        >
      </v-col>
    </v-row>
    <analytic-dialog></analytic-dialog>
  </v-flex>
</template>

<script>
import AnalyticDialog from "@/components/analytics/AnalyticDialog.vue";
import axios from "@/api";

export default {
  name: "AnalyticView",
  components: { AnalyticDialog },
  data: () => ({
    items: [
      {
        title: "xx",
        text: "Eventos Cadastrados",
      },
      {
        title: "xx",
        text: "Apostas no total",
      },
      {
        title: "xx",
        text: "Valor médio (R$) apostado por evento",
      },
      {
        title: "xx",
        text: "Valor médio (R$) de todas as apostas da plataforma",
      },
      {
        title: "xx",
        text: "Ganho total (R$) Valeu",
      },
      {
        title: "xx",
        text: "Ganho médio (R$) por evento Valeu",
      },
      {
        title: "xx",
        text: "Valor total (R$) apostado por usuário, por aposta",
      },
      {
        title: "xx",
        text: "Valor médio (R$) de aposta por usuário",
      },
    ],
  }),
  methods: {
    openDialog(type) {
      if (type == "event") {
        axios.get("/admin/events_all").then(({ data }) => {
          console.log(data);
          this.$store.dispatch("data/setEvents", data.events);
        });
      } else if (type == "user") {
        axios.get("/admin/users_all").then(({ data }) => {
          console.log(data);
          this.$store.dispatch("data/setUsers", data.users);
        });
      }

      this.$store.dispatch("data/setAnalyticDialog", {
        enabled: true,
        type: type,
      });
    },
  },
};
</script>

<style scoped>
.analityc-text {
  font-family: Barlow;
  font-size: 35px;
  font-weight: 700;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: center;
}

.box-shadow-rounded {
  box-shadow: 0px 0px 23px 1px rgb(0 0 0 / 25%) !important;
  border-radius: 18px !important;
}
</style>
