import axios from "axios";
import router from "./router/index.js";

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

// Add a response interceptor
api.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    if (error.response.status == 401 && router.currentRoute.name != "login") {
      router.push({ name: "login" });
    } else {
      return Promise.reject(error);
    }
  }
);

export default api;
