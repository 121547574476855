<template>
  <v-container class="fill-height justify-center">
    <v-card flat width="400">
      <v-card-title class="mb-10 justify-center">
        <v-img max-width="150" src="@/assets/valeu-logo.png" />
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="email"
          background-color="backgroundBlue"
          color="black"
          filled
          solo
          flat
          label="Email"
          prepend-inner-icon="$email"
        />
        <v-text-field
          v-model="password"
          solo
          flat
          background-color="backgroundBlue"
          filled
          label="Senha"
          prepend-inner-icon="$padlock"
          :append-icon="showPassword ? '$eye' : '$eye_off'"
          @click:append="showPassword = !showPassword"
          :type="showPassword ? 'text' : 'password'"
        />
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn class="gradientbutton white--text" x-large block @click="login">
          Entrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import axios from "@/api";
import { mapActions } from "vuex";
export default {
  data: () => ({
    showPassword: false,
    email: "",
    password: "",
  }),
  methods: {
    login() {
      axios
        .post("admin/auth/login", {
          email: this.email,
          password: this.password,
        })
        .then(({ data }) => {
          // setar current user na store
          this.setUser(data.admin);
          // salvar token no localstorage
          let access_token = data.meta.access_token;
          localStorage.access_token = access_token;
          // setar axios default header
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${access_token}`;

          this.setAlert({
            type: "success",
            text: "Login com sucesso!",
            enabled: true,
          });

          this.$router.push({ name: "admin" });
        })
        .catch((err) => {
          this.setAlert({
            type: "error",
            text: err.response.data.message || "Erro!",
            enabled: true,
          });
        });
    },

    ...mapActions({
      setAlert: "base/setAlert",
      setUser: "user/setUser",
    }),
  },
};
</script>
<style>
.gradientbutton {
  background: linear-gradient(286.17deg, #002aa0 0%, #1c2360 100%);
  box-shadow: 3.5px 7px 20px rgba(104, 66, 255, 0.25);
  border-radius: 8px;
}
.theme--light.v-label,
.theme--light.v-icon {
  color: #171a20;
}
/* .theme--light.v-input input {
  color: black;
} */
</style>
