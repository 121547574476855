<template>
  <v-card
    @click="$emit('click')"
    width="436"
    height="155"
    class="box-shadow-rounded mb-4 mx-3"
  >
    <v-row class="justify-end ma-0 pt-2 px-3">
      <v-icon v-if="event.carousel" color="primary">mdi-star</v-icon>
      <span v-else class="ma-3"></span>
    </v-row>
    <v-row class="align-center ma-0 pt-0 pb-2 px-3">
      <v-col cols="4">
        <v-row class="justify-center">
          <v-card class="rounded-circle">
            <v-img
              contain
              class="ma-2"
              :src="
                getTeamPicture(event.first_participant_info.participant_picture)
              "
              height="49"
              width="49"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-row> </template
            ></v-img>
          </v-card>
          <v-card class="rounded-circle">
            <v-img
              contain
              class="ma-2"
              :src="
                getTeamPicture(
                  event.second_participant_info.participant_picture
                )
              "
              height="49"
              width="49"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-card>
        </v-row>
      </v-col>
      <v-col cols="8">
        <v-row>
          <v-col class="ml-2"
            ><v-row class="justify-start">
              <div class="team-title-h6 text-uppercase">
                {{ getParticipantName(event.first_participant_info) }}
              </div>
              &nbsp;
              <div class="d-inline-flex align-center">vs</div>
              &nbsp;
              <div class="team-title-h6 text-uppercase">
                {{ getParticipantName(event.second_participant_info) }}
              </div>
            </v-row></v-col
          >
        </v-row>
        <v-row>
          <v-col
            ><v-row class="justify-start">
              <v-icon :color="event.status.status">mdi-circle-medium</v-icon>
              <div class="body-1 justify-center">
                {{ event.status.translated_status }}
              </div></v-row
            ></v-col
          >
        </v-row>
      </v-col>
    </v-row>
    <v-divider />
    <v-row class="align-center ma-0 pb-4 pt-2 px-2">
      <v-col
        ><v-row class="justify-center liveBlue--text">
          <v-icon color="red">$calendar</v-icon>
          <div class="body-1 mx-2">
            {{ formatDate(event.start_date) }}
          </div>
        </v-row></v-col
      >
      <v-col
        ><v-row class="justify-center liveBlue--text">
          <v-icon color="liveBlue">$cup</v-icon>
          <div class="mx-2">
            {{ event.competition.name }}
          </div>
        </v-row></v-col
      >
    </v-row>
  </v-card>
</template>

<script>
import moment from "moment";
export default {
  data: () => ({}),
  props: { event: {} },
  methods: {
    getParticipantName(info) {
      if (info.participant_name.length > 8) {
        return info.participant_short_name;
      } else {
        return info.participant_name;
      }
    },
    getTeamPicture(url) {
      if (url == null) {
        return require("@/assets/team-placeholder.svg");
      }
      return url;
    },
    formatDate(string) {
      return moment(string).format("DD [de] MMM [-] HH[h]mm");
    },
  },
};
</script>
<style scoped>
.box-shadow-rounded {
  box-shadow: 0px 0px 23px 1px rgb(0 0 0 / 25%) !important;
  border-radius: 18px !important;
}
.team-title-h6 {
  font-size: 1.25rem !important;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.0125em !important;
  font-family: "Barlow Bold" !important;
}
</style>
