<template>
  <v-flex>
    <v-row class="align-center">
      <v-col cols="3">
        <h1>Gerenciar Eventos</h1>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="4">
        <v-row class="align-center ma-0">
          <v-text-field
            outlined
            hide-details="true"
            prepend-inner-icon="mdi-magnify"
            v-model="search"
            color="black"
            label="Buscar"
            class="mr-3 rounded-lg"
          ></v-text-field>
          <v-btn
            outlined
            color="outlineGrey"
            class="mr-3 rounded-lg"
            @click.stop="openFilters"
          >
            <v-icon color="black">$filter</v-icon>
          </v-btn>
          <v-btn
            outlined
            color="outlineGrey"
            class="mr-3 rounded-lg"
            @click.stop="newEvent"
          >
            <v-icon color="black">mdi-plus</v-icon>
          </v-btn>
        </v-row>
      </v-col>
    </v-row>

    <v-row v-if="events && events.length > 0">
      <event-card
        @click="getEvent(event.id)"
        v-for="event in events"
        :key="event.id"
        :event="event"
      ></event-card>
    </v-row>

    <v-row v-if="events && events.length > 0" class="justify-center">
      <v-pagination
        v-model="pagination.current_page"
        :length="pagination.total_pages"
        @input="getData($event)"
      ></v-pagination>
    </v-row>

    <v-row v-else class="justify-center">
      <span class="text-h5"> Não existem eventos no momento </span>
    </v-row>

    <event-dialog @reload="getData()" @reload_dialog="getEvent"></event-dialog>
    <event-filter></event-filter>
  </v-flex>
</template>

<script>
import moment from "moment";
import { debounce } from "@/helpers";
import axios from "@/api";

import { mapGetters } from "vuex";
import EventCard from "@/components/events/EventCard.vue";
import EventFilter from "@/components/events/EventFilter.vue";
import EventDialog from "@/components/events/EventDialog.vue";

export default {
  name: "EventView",
  components: { EventCard, EventFilter, EventDialog },
  data: () => ({
    search: "",
    pagination: {
      current_page: 1,
      total_pages: 1,
      total: 0,
      per_page: 50,
    },
  }),
  created() {
    moment.locale("pt-br");
    this.getData();
    this.getModalities();
  },
  methods: {
    getEvent(id) {
      axios.get(`admin/events/${id}`).then(({ data }) => {
        this.$store.dispatch("event/setEvent", data.event);
        this.$store.dispatch("event/setEventDialog", {
          enabled: true,
          type: "view",
        });
      });
    },
    getData(page = 1) {
      const params = this.buildRequestParams(page);

      axios
        .get("admin/events", { params: params })
        .then(({ data }) => {
          this.$store.dispatch("event/setEvents", data.events);
          this.pagination = data.meta.pagination;
        })
        .catch((err) => console.log(err));
    },
    getModalities() {
      axios.get("/admin/modalities").then(({ data }) => {
        this.$store.dispatch("event/setModalities", data.modalities);
      });
    },
    newEvent() {
      this.$store.dispatch("event/setEvent", {
        id: null,
        title: "",
        first_participant_info: {
          first_participant: "",
          first_participant_short_name: "",
          first_participant_picture: null,
        },
        second_participant_info: {
          second_participant: "",
          second_participant_short_name: "",
          second_participant_picture: null,
        },
        start_date: "",
        status: {
          status: "",
          translated_status: "",
        },
        competition: {
          id: null,
          name: "",
        },
        markets: {
          market_winner: false,
          market_goals: false,
          market_both_score: false,
        },
      });
      this.$store.dispatch("event/setEventDialog", {
        enabled: true,
        type: "create",
      });
    },
    openFilters() {
      this.$store.dispatch("event/setFilterDialog", true);
    },
    buildRequestParams(page) {
      const params = new URLSearchParams();
      if (
        this.search != null &&
        this.search != undefined &&
        this.search != ""
      ) {
        params.append("name", this.search);
      }
      if (
        this.filters.competition_id != null &&
        this.filters.competition_id != undefined
      ) {
        params.append("competition", this.filters.competition_id);
      }
      if (
        this.filters.statuses != null &&
        this.filters.statuses != undefined &&
        this.filters.statuses.length > 0
      ) {
        params.append("status", this.filters.statuses.join(","));
      }
      if (this.filters.date != null && this.filters.date != undefined) {
        params.append("date", this.filters.date);
      }
      if (page != null && page != undefined) {
        params.append("page", page);
      }
      return params;
    },
  },
  computed: {
    ...mapGetters({
      filters: "event/currentFilters",
      events: "event/events",
    }),
  },
  watch: {
    search: debounce(function () {
      this.getData();
    }, 500),
    filters: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.v-card--link:hover {
  border: 1px solid #4271ff;
}
.v-card--link:focus:before {
  opacity: 0;
}
.v-btn:not(.v-btn--round).v-size--default {
  width: 40px;
  height: 40px;
  min-width: 40px;
}
.v-text-field--outlined >>> fieldset {
  border-color: #cbd5e0;
}
</style>
