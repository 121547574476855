import Vue from "vue";
import Vuex from "vuex";
import user from "./user";
import base from "./base";
import event from "./event";
import data from "./data";

Vue.use(Vuex);

export default new Vuex.Store({
  namespaced: true,
  modules: { user, base, event, data },
});
