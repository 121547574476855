export default {
  namespaced: true,
  state: {
    user: {},
  },
  getters: {
    currentUser: (state) => {
      return state.user;
    },
  },
  mutations: {
    SET_USER(state, payload) {
      state.user = payload;
    },
  },
  actions: {
    setUser({ commit }, payload) {
      commit("SET_USER", structuredClone(payload));
    },
  },
};
